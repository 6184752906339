import React, { Suspense, useState, useEffect } from 'react';

const LazyDotLottieReact = React.lazy(() =>
  import('@lottiefiles/dotlottie-react').then((module) => ({
    default: module.DotLottieReact,
  }))
);

const LottieAnimation = ({
  s3Url,
  className,
  height,
  onClick,
  speed = 1.5,
  width,
}) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Preload Lottie library when the component mounts
    const loadLottie = async () => {
      await import('@lottiefiles/dotlottie-react');
      setIsReady(true);
    };
    loadLottie();
  }, []);

  if (!isReady) return <Fallback height={height} width={width} />;

  return (
    <div className={className} onClick={onClick}>
      <Suspense fallback={<Fallback height={height} width={width} />}>
        <LazyDotLottieReact
          autoplay
          loop
          style={{ width: width, height: height }}
          speed={speed}
          src={s3Url}
        />
      </Suspense>
    </div>
  );
};

const Fallback = ({height, width}) => {
  return (
    <div style={{ width: width, height: height }}></div>
  );
}

export default LottieAnimation;
