import React from 'react';
import LottieAnimation from 'components/lottie-animation/component.js';
import './styles.scss';

export const LOADING_ICON_DOT_LOTTIE_URL = "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/Icons/giftBox_v2.lottie";

const LoadingIcon = () => {
  const isMobile = localStorage.getItem('isMobile') === 'true';
  const loadingGifWidth = isMobile ? 200 : 300;

  return (
    <div className="loadingImageContainer">
      <LottieAnimation
        s3Url={LOADING_ICON_DOT_LOTTIE_URL}
        className="loadingImageStyle"
        height={loadingGifWidth}
        width={loadingGifWidth}
      />
    </div>
  );
}

export default LoadingIcon;