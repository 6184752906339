import React, { useState } from 'react';
import StyledText from '../styled-text/component.js';
import './styles.scss';
import classNames from 'classnames';

const CheckMarkSvg = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <polyline fill="none" stroke="white" strokeWidth="2" points="2 14 9 20 22 4"/>
    </svg>
  );
}

const XMarkSvg = (props) => {
  return (
    <svg {...props} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="white"/>
    </svg>
  );
}

const StyledSwitch = ({
  containerClassName = '', 
  containerStyle = {},
  hideNsfwFilter,
  parentFunction,
  startChecked,
  text,
  textIsAbove,
  title,
}) => {
  const [checked, setChecked] = useState(startChecked || false);
  const [hasBeenChecked, setHasBeenChecked] = useState(false);

  const handleClick = () => {
    setChecked(!checked);
    setHasBeenChecked(true);
    parentFunction && parentFunction();
  }

  const checkboxContainerClassName = checked ?
  `checkboxContainer checkboxContainerChecked ${textIsAbove && 'checkboxIsBelow'} ${containerClassName}` :
  `checkboxContainer ${textIsAbove && 'checkboxIsBelow'} ${containerClassName}`;
  const leftSideCheckboxClassName = checked ? "leftSideCheckbox leftSideCheckboxChecked" : "leftSideCheckbox";
  const rightSideCheckboxClassName = checked ? "rightSideCheckbox rightSideCheckboxChecked" : "rightSideCheckbox";

  return (
    <div 
      className={classNames('styledSwitchTextAndBoxContainer', containerClassName, {'styledSwitchTextAndBoxContainerTextIsAbove': textIsAbove}, {"hideSwitch": hideNsfwFilter})} 
      style={containerStyle}
    >
      <StyledText
        textClassName={classNames(`styledSwitchText`, {'styledSwitchTextIsAbove': textIsAbove})}
        text={text} 
      />
      <div className={checkboxContainerClassName} onClick={() => handleClick()} title={title}>
        <div className={leftSideCheckboxClassName}>
          {checked && (
            <CheckMarkSvg
              alt="Checkmark off"
              width="18px" 
              height="18px" 
            />
          )}
        </div>
        <div 
          className={classNames(
            rightSideCheckboxClassName, 
            { uncheckingSwitchAnimation: (hasBeenChecked && !checked) }
          )}
        >
          {!checked && (
            <XMarkSvg
              alt="Checkmark on"
              width="18px" 
              height="18px" 
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StyledSwitch;
