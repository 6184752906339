import React, { useState, useEffect, Suspense, Fragment } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import StyledTitle from '../styled-title/component.js';
import './styles.scss';

export const SubmenuSvg = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 15L12 7L20 15" stroke="currentColor" strokeWidth="2"/>
    </svg>
  );
}

/**
 * @param {React.ReactNode} props.children content to be displayed in the dropdown
 * @param {String} props.title string for the displaying text
 * @param {String} props.path optional path value to navigate if applicable
 * @param {{
 *   title: String,
 *   path: String,
 *   subMenuItems: { title: String, path: String }[]
 * }[]} props.menuItems  optional menu items that include optional subMenuItems
 * @param {String} props.contentWidth optional width value
 */
const Dropdown = ({
  title,
  path = undefined,
  menuItems = [],
  contentWidth,
  children,
  titleClassName,
}) => {
  const [inactiveTimeout, setInactiveTimeout] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [subMenuIndex, setSubMenuIndex] = useState(-1);
  const location = useLocation();

  useEffect(() => {
    hideDropdown();
  }, [location]);

  const menuItemsUI = menuItems?.map((item, index) => {
    const submenuIsOpen = (subMenuIndex === index) && subMenuIndex >= 0;

    return (
      <div
        className='dropdownMenuItem'
        key={`${item.title}MenuItem`}
        onMouseEnter={() => handleSubMenuItemActive(index)}
        onMouseOver={() => handleSubMenuItemActive(index)}
      >
        <StyledTitle
          containerClassName="dropdownMenuTitleContainer"
          text={item.title}
          titleClassName="dropdownMenuTitleTextContainer"
          url={item.path}
        />
        {
          item.subMenuItems?.length &&
            <SubmenuSvg
              alt={`${item.title} submenu icon`}
              className={classNames('subMenuItemIcon', {'subMenuItemIconFocused': submenuIsOpen})}
            />
        }
      </div>
    );
  });

  const subMenuItemsUI = subMenuIndex < 0 ? null :
    menuItems?.[subMenuIndex].subMenuItems.map((item) =>
      <div 
        key={`${item.title} link`}
        className='dropdownSubMenuItem'
      >
        <StyledTitle
          text={item.title}
          url={item.path}
        />
      </div>
    );

  const isDesktop = (localStorage.getItem('isDesktop') === 'true');
  const chevronDownWidth = isDesktop ? 24 : 18;
  const chevronDownHeight = isDesktop ? 24 : 18;

  const hideDropdown = () => {
    setShowDropdown(false);
    setSubMenuIndex(-1);
  }

  const handleDropdownActive = () => {
    clearTimeout(inactiveTimeout);
    setShowDropdown(true);
  }

  const handleDropdownInactive = () => {
    setInactiveTimeout(setTimeout(() => {
      hideDropdown();
    }, 500));
  }

  const handleSubMenuItemActive = (index) => {
    const subMenuIndex = menuItems[index].subMenuItems?.length
      ? index
      : -1;
    setSubMenuIndex(subMenuIndex);
  }

  return (
    <div
      className="dropdownContainer"
      onMouseEnter={handleDropdownActive}
      onMouseOver={handleDropdownActive}
      onMouseLeave={handleDropdownInactive}
    >
      <StyledTitle
        url={path}
        text={title}
        titleClassName={`${window.location.pathname === path && "selectedProfileHeaderTitle"} ${titleClassName}`}
      />
      <SubmenuSvg
        alt={`${title} dropdown icon`}
        className={classNames('dropdownIcon', { active: showDropdown })}
        height={chevronDownHeight}
        width={chevronDownWidth}
      />
      <div
        className={classNames('dropdownContent', 'truncated', showDropdown ? 'active' : 'inactive')}
        style={{width: contentWidth}}
      >
        <Suspense fallback={<div></div>}>
          {showDropdown &&
            <Fragment>
              {children}
              {menuItemsUI}
              <div className={classNames('dropdownSubMenu', subMenuIndex < 0 ? 'inactive' : 'active')}>
                {subMenuItemsUI}
              </div>
            </Fragment>
          }
        </Suspense>
      </div>
    </div>
  );
}

export default Dropdown;
