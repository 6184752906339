import React from 'react';
import {TYPES_OF_QUIZZES} from './constants.js';

// sorts an object by the given property
export function sortObject(property){
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
      let aVal = ((a.giftDisplayData || {})[property]) || ((a || {})[property]);
      let bVal = ((b.giftDisplayData || {})[property]) || ((b || {})[property]);
      if(!isNaN(aVal)){
        aVal = parseFloat(aVal);
        bVal = parseFloat(bVal);
      }
      var result = (aVal < bVal) ? -1 : (aVal > bVal) ? 1 : 0;
      return result * sortOrder;

    }
}

export function getUrlVars(url) {
    var vars = {};
    (url || window.location.href).replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

export function getQuizType() {
  const pathNameWithSlash = window.location.pathname.replace("Results", "");
  return pathNameWithSlash.replace("/", "");
}

export function isAQuiz() {
  return TYPES_OF_QUIZZES.includes(getQuizType());
}

export function convertToWebp(previousSrcUrl) {
  if (!previousSrcUrl) {
    return '';
  }
  let webpSrcUrl = previousSrcUrl.replace(".png", ".webp");
  return webpSrcUrl.replace(".jpg", ".webp");
}

export const XMarkSvg = (props) => {
  return (
      <svg {...props} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="#FF1654"/>
      </svg>
  );
}

export const RightChevronSvg = (props) => {
  return (
      <svg {...props} viewBox="0 0 24 24" stroke="#FF1654" fill="#FF1654" xmlns="http://www.w3.org/2000/svg">
          <rect x="0" stroke="none" fill="none" width="24" height="24"/>
          <g>
              <path d="M10 20l8-8-8-8-1.414 1.414L15.172 12l-6.586 6.586"/>
          </g>
      </svg>
  )
}

export const checkDeviceIsPrimarilyATouchscreen = () => {
  if(window.matchMedia("(pointer: coarse)").matches) {
    return true;
  } else {
    return false;
  }
}

const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const waitBeforeAction = (milliseconds, action) => {
  sleep(milliseconds).then(() => {
    action && action();
  });
}

export const getCurrentWishlistId = () => {
  if ((window.location.pathname).includes("/wishlists/")) {
    return (window.location.pathname).split("/wishlists/")[1];
  }
}