import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import Wizard from '../wizard/component.js';
import ErrorBoundary from '../error-boundary/component.js';
import { APP_TITLE, APP_META_DESCRIPTION } from './constants.js';
import { LOADING_ICON_DOT_LOTTIE_URL } from 'components/loading-icon/component.js';

const convertPngToWebp = (pngUrl) => {
  return pngUrl.replace("png", "webp");
}

const isTouchDevice = () => {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0))
     && window.matchMedia("(pointer: coarse)").matches;
}

const App = ({
  goToSectionIndex,
  metaDescription,
  metaTitle,
  noIndex,
  pageType,
  hidePriceFilter,
  hideNSFWToggle,
  showNsfw,
  imageSrcOne,
  imageSrcTwo,
  title,
}) => {
  const isSmallMobile = window.innerWidth <= 375;
  const isMobile = window.innerWidth < 500 || isTouchDevice();
  const isTablet = window.innerWidth > 500 && isTouchDevice();
  const isLaptop = (window.innerWidth >= 768) && (window.innerWidth <= 1599);
  const isDesktop = window.innerWidth >= 1600;
  const showNsfwGlobal = localStorage.getItem('showNsfwGlobal') === 'true';
  localStorage.setItem('isSmallMobile', isSmallMobile);
  localStorage.setItem('isMobile', isMobile);
  localStorage.setItem('isTablet', isTablet);
  localStorage.setItem('isLaptop', isLaptop);
  localStorage.setItem('isDesktop', isDesktop);
  localStorage.setItem('showNsfwGlobal', showNsfwGlobal);

  let imagePreloads;
  if (pageType === "Quiz") {
    const homePageStill = isMobile ? "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/HomepageImages/MobileHomePageStill.webp" :
    isLaptop ? "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/HomepageImages/LaptopHomePageStill.webp"
    : "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/HomepageImages/DesktopHomePageStill.webp";
    imagePreloads = [
      homePageStill,
    ];
  } else if (pageType === "About") {
    const swolehallaImage = isMobile ? "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/AboutImages/FrontBannerMobile_gold.webp" : "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/AboutImages/swolehallaHomepage.webp"
    imagePreloads = [
      "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/AboutImages/ianbarbour.webp",
      "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/AboutImages/suLiResized.webp",
      "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/AboutImages/gerrySelianResized.webp",
      "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/AboutImages/AnastaziaProvost.webp",
      "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/AboutImages/AshBruneau.webp",
      "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/HomepageImages/HomePageStill.webp",
      swolehallaImage,
    ];
  } else if (pageType === "Mentions") {
    
  } else if (pageType === "Explore") {
    imagePreloads = isMobile ? 
      [
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImagesMobile/forHer.webp",
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImagesMobile/forHim.webp",
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImagesMobile/all_Long.webp",
      ]
       : 
      [
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImages/all_Long.webp",
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImages/forHim_Square.webp",
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImages/forHer_Square.webp",
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImages/popular_Long.webp",
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImages/free_Tall.webp",
        "https://wtfdotheywant-images.s3.us-west-2.amazonaws.com/exploreImages/unique_Tall.webp",
      ];
  } else if (pageType === "StaticResults") {
    imagePreloads = [
      LOADING_ICON_DOT_LOTTIE_URL,
      convertPngToWebp(imageSrcOne),
      convertPngToWebp(imageSrcTwo),
    ];
  } else { // results & wishlist
    imagePreloads = [
      LOADING_ICON_DOT_LOTTIE_URL
    ];
  }    

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{metaTitle || APP_TITLE}</title>
        <meta name="description" content={metaDescription || APP_META_DESCRIPTION} />
        {noIndex &&
          <meta name="robots" content="noindex,follow" />
        }
        {imagePreloads &&
          imagePreloads.map((imageUrl) => {
            const isLottieFile = imageUrl.includes(".lottie");
            return isLottieFile ?
              <link
                key={imageUrl}
                rel="preload" 
                as="fetch"
                href={imageUrl}
                crossOrigin="anonymous"
              /> :
              <link
                key={imageUrl}
                rel="preload" 
                as="image"
                href={imageUrl}
              />;
          })
        }       
      </Helmet>
      <Suspense fallback={<div></div>}>
        <Wizard 
          goToSectionIndex={goToSectionIndex}
          metaDescription={metaDescription}
          pageType={pageType}
          hidePriceFilter={hidePriceFilter}
          hideNSFWToggle={hideNSFWToggle}
          showNsfw={showNsfw}
          imageSrcOne={imageSrcOne}
          imageSrcTwo={imageSrcTwo}
          title={title}
        />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;